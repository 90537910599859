import React from 'react'
import { graphql, Link } from "gatsby"
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import styled from "@emotion/styled"

import { components } from '../slices'
import Layout from '../components/layout'

const Section = styled.section`
padding: 6vh 4vw 4vh 4vw;
box-sizing: border-box;
background-color: rgb(255,255,255);
margin-top: 12.5vh;
position: relative;
&:nth-of-type(1) {
    margin-top: 5vh;
}
@media (max-width: 599px) {
  margin-left: 10vw;
  margin-right: 4vw;
}
a.button {
  text-decoration: none;
  color: rgb(0,0,0);
  transition: all 0.3s ease;
  display: block;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 0.75vw 1vw 0.5vw 1vw;
  text-transform: uppercase;
  text-shadow: 1px 1px 3px rgb(0 0 0 / 20%);
  font-family: "DIN Bold";
  font-size: .875rem;
  letter-spacing: 2px;
  border-radius: 5px;
  margin-top: 1vh;
  background-image: -webkit-repeating-linear-gradient(135deg,rgba(241,90,36,.8),rgba(241,90,36,.8) 30px,rgba(241,90,36,1) 30px,rgba(241,90,36,1) 60px);
  background-image: repeating-linear-gradient(135deg,rgba(241,90,36,.8),rgba(241,90,36,.8) 30px,rgba(241,90,36,1) 30px,rgba(241,90,36,1) 60px);
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  opacity: 0.75;
  color: rgba(255,255,255,1);
  border: none;
  &:hover {
    box-shadow: 0 0 10px rgb(36 168 224 / 80%);
    opacity: 1;
  }@media (max-width: 599px) {
    padding: 12px 12px 8px 12px;
  }
  
}
header {
  position: absolute;
  right: 4vw;
  top: 4vh;
  text-transform: uppercase;
  letter-spacing: 3px;
  font-size: .9125rem;
  @media (max-width: 599px) {
    right: inherit;
    top: 10vh;
    width: 92%;
    box-sizing: border-box;
    text-align: center;
  }
  
}
h2 {
  font-size: 2.5rem;
  line-height: 1em;
  font-family: "Trade Gothic Bold";
  color: rgb(0,0,0);
  text-transform: uppercase;
  margin-bottom: .75em;
  letter-spacing: -1px;
}
h3 {
  @media (max-width: 599px) {
    font-size: 1.5rem;
  }
}
h3.week {
  text-align: center;
  font-size: 1.875rem;
  font-family: "Chaparral Light";
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 2vh 4vh 2vh 4vh;
  margin-bottom: 3vh;
  border-top: none;
  background-color: rgba(0,0,0,0.4);
  position: absolute;
  top: 0;
  z-index: 3;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%);
  @media (max-width: 599px) {
    left: 0;
    width: 100%;
    box-sizing: border-box;
    transform: none;
    font-size: 1.66rem;
  }
}
h4 {
  font-family: "DIN Bold";
  color: rgb(0,0,0);
  font-size: 1.125rem;
  line-height: 1.75em;
  transition: all 0.3s ease;
  border-top: solid 1px rgba(0,0,0,0.1);
  padding-top: 2.5vh;
  margin-top: 6vh;
}
h5 {
  font-size: .9375rem;
  line-height: 1.75em;
  strong {
    font-family: "DIN Bold";
  }
}
h6 {
  font-family: "Trade Gothic Light";
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: .8125rem;
    line-height: 1.75em;
    color: rgb(0,0,0);
    margin-botom: .5vh;
    strong {
      font-family: 'DIN Bold';
      color: rgb(0,0,0);
    }
}
article {
  margin-top: 2vh;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vw;
  @media (max-width: 599px) {
    display: block;
  }
}
p {
  margin-bottom: 1.5em;
  color: rgba(0,0,0,.7);
  font-size: 1.125rem;
  line-height: 1.375em;
  font-family: "Chaparral Regular";
  strong {
    font-family: 'DIN Bold';
    color: rgb(0,0,0);
    font-size: .9em;
    transition: all 1s ease;
  }
}
div.caption {
  color: rgba(0,0,0,0.33);
  margin-top: 12px;
  font-size: .8125rem;
  text-align: center;
  margin-bottom: 1.5vh;
}
ul {
  margin-bottom: 1.5em;
  li {
    padding-bottom: 0.75vh;
    margin-bottom: 1vh;
    font-size: .9375rem;
    line-height: 1.375em;
    border-bottom: solid 1px rgba(0,0,0,0.2);
    text-align: left;
    width: 90%;
    transition: all 1s ease;
    &:nth-of-type(1) {
      padding-top: .75vh;
      border-top: solid 1px rgba(0,0,0,0.2);
    }
    em {
      border-bottom: dashed 1px rgba(0,0,0,0.6);
    }
    strong {
      font-family: 'DIN Bold';
    }
  }
}
.card.lecture {
  border-top: solid 1px rgba(0,0,0,0.1);
  padding-top: 2.5vh;
  margin-top: 6vh;
  h4 {
    padding: 0;
    margin: 0;
    border: 0;
    font-size: 1rem;
  }
  p {
    border-bottom: dashed 1px rgb(0,0,0);
    padding-bottom: 20px;
    margin-bottom: 20px;;
  }
  > div {
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
    padding: 20px;
  }
  .thumbnail {
    a {
      display: grid;
      width: 100%;
      grid-template-columns: 3fr 5fr;
      column-gap: 2vw;
      border-bottom: 0;
      &:hover {
        color: rgb(36,168,224);
      }
      @media (max-width: 599px) {
        column-gap: 4vw;
      }
    }
  }
}
`
const WeekTemplate = ({data}) => {
    if (!data) return null
    const doc = data.prismicClassMeeting.data

    return (
        <Layout>
             <Section key={data.prismicClassMeeting.id}>
            <header>{doc.topic.text}</header>
            <h3 className="week">{doc.title.text} - {doc.class_date}</h3>
            <article>
            <SliceZone slices={doc.body} components={components} />
            </article>
          </Section>
        </Layout>
    )
}

export default WeekTemplate

export const pageQuery = graphql`
query ($id: String) {
    prismicClassMeeting(id: {eq: $id}) {
    id
    data {
      class_date(formatString: "MM-DD-YY")
      title {
        text
      }
      topic {
        text
      }
      body {
          ... on PrismicSlice {
              slice_type
          }
          ...ClassMeetingDataBodyCharette
          ...ClassMeetingDataBodyReading
          ...ClassMeetingDataBodyResource
          ...ClassMeetingDataBodyLecture
          ...ClassMeetingDataBodyButton
      }
    }
  }
}`
